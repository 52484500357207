import { ADD_LEAD, ADD_LEAD_FAILURE, GET_LEAD,GET_INSURANCE_PLANS,GET_POLICY_BY_ID,GET_POLICY_BY_ID_FAILURE,
  GET_LEAD_COUNT,GET_LEAD_COUNT_FAILURE,GET_UPLOAD_HISTORY,GET_UPLOAD_HISTORY_FAILURE } from '../actions/types'
const initialState = {
  leads: [],
  leadError: '',
  plans:[],
  leadById:{},
  leadByIdError:'',
  leadCount:{},
  leadCountError:'',
  uploadHistory:[],
  uploadHistoryError:''
}

const leadReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LEAD:
        return Object.assign({}, state, {
            leads: action.payload
        })
      case GET_UPLOAD_HISTORY:
        return Object.assign({}, state, {
          uploadHistory: action.payload
        })
      case GET_INSURANCE_PLANS:
        return Object.assign({}, state, {
            plans: action.payload
        })
      case ADD_LEAD:
        return Object.assign({}, state, {
            leadError: ''
        })
      case GET_POLICY_BY_ID:
        return Object.assign({}, state, {
          leadById: action.payload
        })
      case GET_LEAD_COUNT:
        return Object.assign({}, state, {
          leadCount: action.payload
        })
      case ADD_LEAD_FAILURE:
        return Object.assign({}, state, {
            leadError: action.payload.error || action.payload.message
        })
      case GET_POLICY_BY_ID_FAILURE:
        return Object.assign({}, state, {
          leadByIdError: action.payload.error || action.payload.message
        })
      case GET_LEAD_COUNT_FAILURE:
        return Object.assign({}, state, {
          leadCountError: action.payload.error || action.payload.message
        })
      case GET_UPLOAD_HISTORY_FAILURE:
        return Object.assign({}, state, {
          uploadHistoryError: action.payload.error || action.payload.message
        })
      default:
        return state
    }
  }
  
  export default leadReducer