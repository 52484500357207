import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Navigate, useLocation } from 'react-router-dom'
import { pathOr } from 'ramda'
import Loader from '../dashboard/Loader'

export const LeadRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')
  const isBrieziAdmin = !!role.includes('BRIEZIADMIN')
  let location = useLocation();
  if (!isLoggedIn || !isLeadAdmin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children
}
export const AgentRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAgent = !!role.includes('MAXLIFEAGENT')
  let location = useLocation();
  if (!isLoggedIn || !isLeadAgent) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children
}

export const CommonLeadRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')
  const isLeadAgent = !!role.includes('MAXLIFEAGENT')
  let location = useLocation();
  if (!isLoggedIn || (!isLeadAdmin && !isLeadAgent)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props.children
}


export const CommonRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')
  const isBrieziAdmin = !!role.includes('BRIEZIADMIN')
  let location = useLocation();
  if (!isLoggedIn || (!isLeadAdmin && !isBrieziAdmin)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <>
      <Loader />
      {props.children}
    </>
  )
}

export const OpenRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')
  const isBrieziAdmin = !!role.includes('BRIEZIADMIN')
  const isLeadAgent = !!role.includes('MAXLIFEAGENT')

  let location = useLocation();
  // if (isLoggedIn && isLeadAdmin) {
  //   return <Navigate to="/home" state={{ from: location }} replace />;
  // }
  // if (isLoggedIn && isBrieziAdmin) {
  //   return <Navigate to="/adminhome" state={{ from: location }} replace />;
  // }
  if (isLoggedIn && isLeadAgent) {
    return <Navigate to="/agenthome" state={{ from: location }} replace />;
  }
  return (
    <>
      <Loader />
      {props.children}
    </>
  )
}

export const AdminRoute = (props) => {
  const isLoggedIn = useSelector(state => pathOr(false, ['Auth', 'isLoggedIn'], state))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')
  const isBrieziAdmin = !!role.includes('BRIEZIADMIN')
  let location = useLocation();
  if (!isLoggedIn || !isBrieziAdmin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <>
      <Loader />
      {props.children}
    </>
  )
}
