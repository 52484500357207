import { combineReducers } from 'redux'
import leadReducer from './leadReducer'
import loaderReducer from './loaderReducer'

const rootReducer = combineReducers({
  Lead: leadReducer,
  Loader: loaderReducer,
})

export default rootReducer
