export const TOGGLE_LOADER = 'TOGGLE_LOADER'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'

export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS'
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE'

export const USER_PREFERENCE_UPDATE = 'USER_PREFERENCE_UPDATE'

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const V1_AUTH_SUCCESS = 'V1_AUTH_SUCCESS'
export const V1_AUTH_FAILURE = 'V1_AUTH_FAILURE'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const ADD_LEAD = 'ADD_LEAD'
export const GET_INSURANCE_PLANS = 'GET_INSURANCE_PLANS'
export const GET_INSURANCE_PLANS_FAILURE = 'GET_INSURANCE_PLANS_FAILURE'
export const ADD_LEAD_FAILURE = 'ADD_LEAD_FAILURE'
export const EDIT_LEAD = 'EDIT_LEAD'
export const EDIT_LEAD_FAILURE = 'EDIT_LEAD_FAILURE'
export const DELETE_LEAD = 'DELETE_LEAD'
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE'
export const GET_POLICY_BY_ID = 'GET_POLICY_BY_ID'
export const GET_POLICY_BY_ID_FAILURE = 'GET_POLICY_BY_ID_FAILURE'
export const GET_LEAD = 'GET_LEAD'
export const GET_LEAD_FAILURE = 'GET_LEAD_FAILURE'
export const GET_LEAD_COUNT = 'GET_LEAD_COUNT'
export const GET_LEAD_COUNT_FAILURE = 'GET_LEAD_COUNT_FAILURE'

export const GET_UPLOAD_HISTORY = 'GET_UPLOAD_HISTORY'
export const GET_UPLOAD_HISTORY_FAILURE = 'GET_UPLOAD_HISTORY_FAILURE'

export const LOGOUT = 'LOGOUT'

export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'