
import React, { Suspense, lazy } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { OpenRoute } from '../components/ProtectedRoute';
const AddLead = lazy(() => import('../container/AddLead'));

const Navigation = () => {
    return (
        <Router>
            <Suspense fallback={<div><LinearProgress /></div>}>
                <Routes>
                    <Route path="/" element={<OpenRoute><AddLead /></OpenRoute>} />
                    <Route path="/home" element={<OpenRoute><AddLead /></OpenRoute>} />
                </Routes>
            </Suspense>
        </Router>
    )
}
export default Navigation
