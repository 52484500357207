import logo from './logo.svg';
import './App.css';
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import Navigation from './routes'
import 'react-notifications/lib/notifications.css'
import Loader from './components/dashboard/Loader';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Navigation />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
